/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { Link, withRouter } from "react-router-dom";
import { Box, Button, Typography } from "sensis-material-ui";
import {
  Card,
  CardContent,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import HelpIcon from "@material-ui/icons/HelpOutlineRounded";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { withGatewayApi } from "../../API";
import {
  getQuotesCount,
  getQuotesIsEligible,
  setLoading,
} from "../../redux/Actions";
import { makeCustomerNumber } from "./CustomerNumberUtils";
import { dtmEvent, eventNames } from "./AdobeDTMUtils";
import { formatDate, expiresInWords } from "./DateUtils";
import Dialog, { withDialogState } from "./Dialog";
import { formatType, formatStatus } from "./QuoteUtils";
import { statusToUrl, openInNewTab } from "./UrlUtils";
import MainContent from "./MainContent";
import FeatureToggle from "./FeatureToggle";

const endDateLabels = {
  awaiting_response: "Expires in",
  interested: "Accepted",
  not_interested: "Declined",
  expired: "Expired",
};

let endDateValues;

export const LabelValue = ({ label, value }) => (
  <>
    <Grid item xs={6}>
      <Typography color="secondary" variant="body2">
        {label}
      </Typography>
    </Grid>
    <Grid item xs={6}>
      {value}
    </Grid>
  </>
);

export const BackButton = ({ status, quotes }) => (
  <Link to={`/quotes/${statusToUrl(status)}`} className="c-dark-grey">
    <Box alignItems="center" display="flex">
      <ChevronLeftIcon />
      Back ({quotes[status]} quote request{quotes[status] > 1 ? "s" : ""})
    </Box>
  </Link>
);

const OptOutModal = ({
  radioSelectedValue,
  optOut,
  setOptOut,
  setRadioSelectedValue,
  props,
  updateStatus,
}) => {
  const radioButtonChange = (e) => {
    setRadioSelectedValue(e.target.value);
  };

  const renderRadioButton = (label, value) => (
    <FormControlLabel control={<Radio />} label={label} value={value} />
  );

  const OptOutOptions = () => (
    <Box px={2}>
      <Box fontSize="14px" pb={1.25}>
        <Box fontWeight={700}>Why aren’t you interested in this request?</Box>
        Please select an option below to help us find better requests for you in
        the future.
      </Box>
      <RadioGroup value={radioSelectedValue} onChange={radioButtonChange}>
        <Grid container>
          <Grid item xs={6}>
            {renderRadioButton("Not my type of work", "0")}
            {renderRadioButton("Job too small", "3")}
            {renderRadioButton("Not enough information", "2")}
            {renderRadioButton("Too far away", "5")}
          </Grid>
          <Grid item xs={6}>
            {renderRadioButton("Fake / spam request", "6")}
            {renderRadioButton("Business closed", "98")}
            {renderRadioButton(`I don't want more requests`, "99")}
          </Grid>
        </Grid>
      </RadioGroup>
      <Box display="flex" justifyContent="flex-end" pb={2}>
        <Box mr={0.5}>
          <Button
            variant="contained"
            size="small"
            width="100%"
            onClick={() => {
              props.dialogState.close();
            }}
          >
            Close
          </Button>
        </Box>
        <OptOutAction />
      </Box>
    </Box>
  );

  const OptOutAction = () => (
    <Button
      variant="contained"
      size="small"
      width="100%"
      color="secondary"
      disabled={!radioSelectedValue}
      onClick={() => {
        dtmEvent(
          eventNames.MY_Q_quote_not_interested_click,
          props?.user?.contact,
          {
            quote: {
              leadId: !props.isPublicRoute
                ? props.match.params.leadId
                : props.match.params.source,
            },
          }
        );

        if (["98", "99"].indexOf(radioSelectedValue) > -1) {
          setOptOut(!optOut);
        } else {
          updateStatus("not_interested");
          props.dialogState.close();
        }
      }}
    >
      Continue
    </Button>
  );

  const OptOutCta = ({
    radioSelectedValue,
    optOut,
    setOptOut,
    props,
    updateStatus,
  }) => (
    <Box px={2}>
      <Box textAlign="center" pb={2}>
        {radioSelectedValue === "98" && (
          <Typography variant="h3">Business is permanently closed</Typography>
        )}

        {radioSelectedValue === "99" && (
          <Typography variant="h3">Stop sending me quote requests</Typography>
        )}
        <Typography variant="h3">
          Please call 1800 034 430 to opt out
        </Typography>
      </Box>

      <Box mui display={{ xs: "block", sm: "none" }}>
        <Box px={1.25}>
          <Button
            color="primary"
            size="small"
            role="link"
            href="tel:1800034430"
            onClick={() => {
              updateStatus("not_interested");
              setOptOut(optOut);
              props.dialogState.close();
            }}
          >
            Call
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Dialog
      header={optOut ? "Not Interested" : <Divider hidden />}
      dialogState={props.dialogState}
      updateStatus={() => {
        // eslint-disable-next-line no-unused-expressions
        ["98", "99"].includes(radioSelectedValue) &&
          !optOut &&
          updateStatus("not_interested");
      }}
      trigger={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Button variant="contained" size="small">
          Not Interested
        </Button>
      }
    >
      {optOut ? (
        <OptOutOptions
          updateStatus={updateStatus}
          radioSelectedValue={radioSelectedValue}
          setOptOut={setOptOut}
          optOut={optOut}
          props={{
            match: props.match,
            dialogState: props.dialogState,
            isPublicRoute: props.isPublicRoute,
            user: props.user,
          }}
        />
      ) : (
        // eslint-disable-next-line react/jsx-indent
        <OptOutCta
          updateStatus={updateStatus}
          radioSelectedValue={radioSelectedValue}
          setOptOut={setOptOut}
          optOut={optOut}
          props={{
            match: props.match,
            dialogState: props.dialogState,
            isPublicRoute: props.isPublicRoute,
            user: props.user,
          }}
        />
      )}
    </Dialog>
  );
};

const QuoteDetails = ({ match, gatewayApi, dialogState, history }) => {
  const quotes = useSelector((state) => state.quotes);
  const user = useSelector((state) => state.session.user);
  const isPublicRoute = useSelector((state) => state.publicRoute.isPublicRoute);

  const [quote, setQuote] = useState(null);
  const [radioSelectedValue, setRadioSelectedValue] = useState(null);
  const [estimatedPrice, setEstimatedPrice] = useState(null);
  const [initialChatMessage, setInitialChatMessage] = useState(null);
  const [optOut, setOptOut] = useState(true);
  const dispatch = useDispatch();
  const theme = useTheme();
  const matchesUp = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesDown = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    transparentBorder,
    iconWithTop,
    iframeContainer,
    iframe,
  } = makeStyles(() => ({
    transparentBorder: {
      border: "transparent",
    },
    iconWithTop: {
      top: 5,
    },
    iframeContainer: {
      position: "relative",
      width: "100%",
      height: "400px",
      overflow: "hidden",
    },
    iframe: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      border: "none",
    },
  }))();

  const updateStatus = async (status) => {
    const data = isPublicRoute
      ? {
          quoteToken: match.params.quoteToken,
          status,
        }
      : {
          businessId: match.params.businessId,
          leadId: match.params.leadId,
          status,
        };

    if (isPublicRoute && status === "awaiting_response") {
      data.source = match.params.source;
    }

    if (status === "interested") {
      if (estimatedPrice) {
        data.priceEstimate = estimatedPrice;
      }
      if (initialChatMessage) {
        data.chatMessage = initialChatMessage;
      }
    }

    if (status === "not_interested" && radioSelectedValue) {
      data.remarks = radioSelectedValue;
    }

    await gatewayApi.patch("/quotes", data);

    if (status !== "awaiting_response") {
      // Reload the quote
      setQuote(null);
      // eslint-disable-next-line no-use-before-define
      await loadQuote();

      if (!isPublicRoute) {
        // Update the global counters
        const customerNumber = makeCustomerNumber(
          user.contact.customerNumber,
          user.contact.apfId
        );
        const quoteSuccessResponse = await gatewayApi.get(
          `/customer/${customerNumber}/quotes?detail=count`
        );
        if (quoteSuccessResponse) {
          dispatch(getQuotesCount(quoteSuccessResponse.data));
          dispatch(getQuotesIsEligible(quoteSuccessResponse.data));
        }
      }
    }
  };

  const loadQuote = async () => {
    if (!quote) {
      dispatch(setLoading({ isLoading: true }));
      const endpoint = isPublicRoute
        ? `/quotes?quoteToken=${match.params.quoteToken}`
        : `/quotes?businessId=${match.params.businessId}&leadId=${match.params.leadId}`;
      const quoteSuccessResponse = await gatewayApi.get(endpoint);
      if (quoteSuccessResponse) {
        endDateValues = {
          awaiting_response: expiresInWords(
            quoteSuccessResponse.data.expiresAt
          ),
          interested: formatDate(quoteSuccessResponse.data.showDetailsAt),
          not_interested: formatDate(quoteSuccessResponse.data.notInterestedAt),
          expired: formatDate(quoteSuccessResponse.data.expiresAt),
        };

        setQuote(quoteSuccessResponse.data);
        dispatch(setLoading({ isLoading: false }));

        // initialView represents a timestamp of when the item was read and
        // every call to updateStatus updates this value, so when it isn't set
        // just call it with the same current status to update this timestamp
        if (
          quoteSuccessResponse.data.status === "awaiting_response" &&
          !quoteSuccessResponse.data.initialView
        ) {
          updateStatus("awaiting_response");
        }

        if (quoteSuccessResponse.data.status === "awaiting_response") {
          if (match.params.status === "accept") {
            updateStatus("interested");
          }

          if (match.params.status === "reject") {
            dialogState.open();
          }
        }
      }
    }
  };

  const mounted = useRef();
  useEffect(() => {
    if (isPublicRoute || user) {
      if (!mounted.current) {
        mounted.current = true;
      } else {
        loadQuote();
      }
    }
  });

  const displayContactDescription = (status) => {
    switch (status) {
      case "awaiting_response":
        return "Their contact details will be revealed once you have expressed interest in the job.";
      case "interested":
        return `It's great to see you're interested in this job. You can now connect with ${quote.consumerDetailName} using the details below.`;
      case "not_interested":
      case "expired":
        return `Contact details are only available for quotes you've expressed interest in.`;
      default:
        console.warn(
          `Unable to displayContactDescription for status code: ${status}`
        );
        return null;
    }
  };

  const mask = (count) => {
    const mask = [];
    for (let i = 0; i < count; i++) {
      mask.push(<i key={i}>&#9679;</i>);
    }
    return mask;
  };

  const openChatUrlHandler = () => {
    dtmEvent(eventNames.MY_Q_quote_message_click, user?.contact, {
      quote: {
        leadId: !isPublicRoute ? match.params.leadId : match.params.source,
      },
    });
    openInNewTab(quote.chatUrl);
  };
  const interestedModal = () => {
    return (
      <Dialog
        header="Interested"
        trigger={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Button color="primary" size="small">
            Interested
          </Button>
        }
      >
        <Box p={2}>
          <Typography variant="body2" color="secondary" px={1}>
            <HelpIcon
              className={iconWithTop}
              color="secondary"
              fontSize="small"
            />
            {`  FYI, these fields are optional`}
          </Typography>
        </Box>
        <Box p={2}>
          <TextField
            className={transparentBorder}
            fullWidth
            label="Estimated Price"
            defaultValue={estimatedPrice}
            maxLength={20}
            variant="outlined"
            inputProps={{ type: "number" }}
            onChange={(e) => {
              setEstimatedPrice(e.target.value);
            }}
          />
        </Box>
        <Box p={2}>
          <TextField
            className={transparentBorder}
            fullWidth
            label="Initial Chat Message"
            defaultValue={initialChatMessage}
            maxLength={255}
            multiline
            rows={3}
            variant="outlined"
            onChange={(e) => {
              setInitialChatMessage(e.target.value);
            }}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            color="primary"
            onClick={() => {
              dtmEvent(eventNames.MY_Q_quote_interested_click, user?.contact, {
                quote: {
                  leadId: !isPublicRoute
                    ? match.params.leadId
                    : match.params.source,
                },
              });
              updateStatus("interested");
            }}
            component={Box}
            width="45%"
          >
            Interested
          </Button>
        </Box>
      </Dialog>
    );
  };

  const yellowChatModal = () => {
    return (
      <Dialog
        header="Yellow Chat"
        trigger={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Button color="primary" size="small">
            Send Message
          </Button>
        }
      >
        <Box width={matchesDown ? "100%" : "500px"}>
          <div className={iframeContainer}>
            <iframe className={iframe} src={quote.chatUrl} title="message" />
          </div>
        </Box>
      </Dialog>
    );
  };

  const renderContactCard = () => {
    const { status } = quote;

    return (
      <Box width="100%">
        <Card>
          <CardContent>
            <Box>
              <Typography gutterBottom variant="h3">
                <Box display="inline" fontWeight="normal">
                  Contact{" "}
                </Box>
                {quote.consumerDetailName}
              </Typography>
            </Box>
            <Box pt={1.5}>
              <Typography color="secondary" variant="body2">
                {displayContactDescription(status)}
              </Typography>
              <br />

              {status === "awaiting_response" && (
                <>
                  <Box mui display={{ xs: "none", md: "block" }}>
                    <Box alignItems="center" display="flex" mb={1}>
                      <PhoneIcon />
                      {`${quote.consumerDetailPhone.substring(0, 4)} `}
                      {mask(5)}
                    </Box>
                    <Box alignItems="center" display="flex" mb={1}>
                      <MailOutlineIcon />
                      {mask(20)}
                    </Box>
                  </Box>

                  <Box
                    mui
                    display="flex"
                    flexDirection={{ xs: "row", md: "column" }}
                    flexWrap={{ xs: "wrap", md: "nowrap" }}
                    textAlign="center"
                  >
                    <Box
                      mui
                      flex={{ xs: "0 50%", md: "auto" }}
                      pr={{ xs: 1, md: 0 }}
                      mb={1}
                    >
                      <FeatureToggle name="featureInterestedModal">
                        {interestedModal()}
                      </FeatureToggle>
                      <FeatureToggle name="featureInterestedModal" featureHide>
                        <Button
                          color="primary"
                          size="small"
                          onClick={() => {
                            dtmEvent(
                              eventNames.MY_Q_quote_interested_click,
                              user?.contact,
                              {
                                quote: {
                                  leadId: !isPublicRoute
                                    ? match.params.leadId
                                    : match.params.source,
                                },
                              }
                            );
                            updateStatus("interested");
                          }}
                        >
                          Interested
                        </Button>
                      </FeatureToggle>
                    </Box>
                    <Box
                      mui
                      flex={{ xs: "0 50%", md: "auto" }}
                      pl={{ xs: 1, md: 0 }}
                    >
                      <OptOutModal
                        radioSelectedValue={radioSelectedValue}
                        setRadioSelectedValue={setRadioSelectedValue}
                        setOptOut={setOptOut}
                        optOut={optOut}
                        props={{
                          match,
                          dialogState,
                          isPublicRoute,
                          user,
                        }}
                        updateStatus={updateStatus}
                      />
                    </Box>
                  </Box>
                </>
              )}

              {status === "interested" && (
                <Box width={{ xs: "100%", sm: "auto" }} mt={{ sm: 2 }}>
                  <Box
                    mui
                    display="flex"
                    flexDirection={{ xs: "row", sm: "row", md: "column" }}
                    flexWrap={{ xs: "wrap", md: "nowrap" }}
                    justifyContent="space-between"
                    textAlign="center"
                  >
                    <Box
                      mui
                      display="flex"
                      flex={{ xs: "0 20%", sm: "0 50%", md: "auto" }}
                      mb={1}
                      order={{ xs: 1, sm: 0 }}
                      pr={{ sm: 1, md: 0 }}
                    >
                      <Box mui display={{ xs: "flex", sm: "none" }} flex="auto">
                        <Button
                          iconOnly
                          size="small"
                          variant="contained"
                          startIcon={<PhoneIcon />}
                          role="link"
                          href={`tel:${quote.consumerDetailPhone}`}
                          onClick={() => {
                            dtmEvent(
                              eventNames.MY_Q_quote_phone_click,
                              user?.contact,
                              {
                                quote: {
                                  leadId: !isPublicRoute
                                    ? match.params.leadId
                                    : match.params.source,
                                },
                              }
                            );
                          }}
                        />
                      </Box>

                      <Box mui display={{ xs: "none", sm: "flex" }} flex="auto">
                        <Button
                          size="small"
                          variant="contained"
                          startIcon={<PhoneIcon />}
                          role="link"
                          href={`tel:${quote.consumerDetailPhone}`}
                          onClick={() => {
                            dtmEvent(
                              eventNames.MY_Q_quote_phone_click,
                              user?.contact,
                              {
                                quote: {
                                  leadId: !isPublicRoute
                                    ? match.params.leadId
                                    : match.params.source,
                                },
                              }
                            );
                          }}
                        >
                          {quote.consumerDetailPhone}
                        </Button>
                      </Box>
                    </Box>

                    <Box
                      mui
                      display="flex"
                      flex={{ xs: "0 20%", sm: "0 50%", md: "auto" }}
                      mb={1}
                      order={{ xs: 1, sm: 0 }}
                      pl={{ sm: 1, md: 0 }}
                    >
                      <Box mui display={{ xs: "flex", sm: "none" }} flex="auto">
                        <Button
                          iconOnly
                          size="small"
                          variant="contained"
                          startIcon={<MailOutlineIcon />}
                          role="link"
                          href={`mailto:${quote.consumerDetailEmail}`}
                          onClick={() => {
                            dtmEvent(
                              eventNames.MY_Q_quote_email_click,
                              user?.contact,
                              {
                                quote: {
                                  leadId: !isPublicRoute
                                    ? match.params.leadId
                                    : match.params.source,
                                },
                              }
                            );
                          }}
                        />
                      </Box>

                      <Box
                        mui
                        display={{ xs: "none", sm: "flex" }}
                        maxWidth={388}
                        flex="auto"
                      >
                        <Button
                          size="small"
                          variant="contained"
                          startIcon={<MailOutlineIcon />}
                          role="link"
                          href={`mailto:${quote.consumerDetailEmail}`}
                          onClick={() => {
                            dtmEvent(
                              eventNames.MY_Q_quote_email_click,
                              user?.contact,
                              {
                                quote: {
                                  leadId: !isPublicRoute
                                    ? match.params.leadId
                                    : match.params.source,
                                },
                              }
                            );
                          }}
                        >
                          <Typography pb={0} noWrap>
                            {quote.consumerDetailEmail}
                          </Typography>
                        </Button>
                      </Box>
                    </Box>

                    <Box
                      mui
                      mb={1}
                      display="flex"
                      flex={{ xs: "0 50%", sm: "auto" }}
                    >
                      <FeatureToggle name="featureIframeLiveChatModal">
                        {yellowChatModal()}
                      </FeatureToggle>
                      <FeatureToggle
                        name="featureIframeLiveChatModal"
                        featureHide
                      >
                        <Button
                          color="primary"
                          size="small"
                          onClick={openChatUrlHandler}
                        >
                          Send Message
                        </Button>
                      </FeatureToggle>
                    </Box>
                  </Box>
                </Box>
              )}

              {(status === "not_interested" || status === "expired") && (
                <Box mui display={{ xs: "block", sm: "none" }}>
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => history.push(`/quotes/${quote.status}`)}
                  >
                    Return to Quotes
                  </Button>
                </Box>
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  };

  const servicingWidth = 420;

  return (
    <MainContent
      header={`Quote Request from ${quote ? quote.consumerDetailName : ""}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(!isPublicRoute &&
        quote && {
          description: <BackButton status={quote.status} quotes={quotes} />,
        })}
    >
      {quote && (
        <>
          <Box
            mui
            width="100%"
            flexDirection="column"
            display={{
              xs: "flex",
              sm: "none",
            }}
          >
            {matchesDown && renderContactCard()}
          </Box>
          <Box display="flex" width="100%" flexWrap="wrap">
            <Box
              mui
              display="flex"
              flexDirection="column"
              flex={{ xs: "auto", md: 1 }}
              mr={{ xs: 0, md: 3 }}
              width="100%"
              py={1.5}
            >
              <Paper component={Box}>
                <Typography variant="body2" color="secondary" p={1.5}>
                  Request Details
                </Typography>
                <Divider />
                <Grid container>
                  <LabelValue
                    label="Status"
                    value={formatStatus(quote.status)}
                  />
                  <LabelValue
                    label="Type"
                    value={formatType(quote.requestType)}
                  />
                  <LabelValue
                    label="Received"
                    value={formatDate(quote.createdAt)}
                  />
                  <LabelValue
                    label={endDateLabels[quote.status]}
                    value={endDateValues[quote.status]}
                  />
                  <LabelValue
                    label="Related Listing"
                    value={`${quote.listingAddressName || ""}${
                      quote.listingAddressName && quote.listingAddressSuburb
                        ? " - "
                        : ""
                    }${quote.listingAddressSuburb || ""}${
                      quote.listingAddressSuburb && quote.listingAddressPostcode
                        ? ", "
                        : ""
                    }${quote.listingAddressPostcode || ""}`}
                  />
                  {quote.status === "not_interested" && (
                    <LabelValue
                      label="Reason"
                      value={quote.notInterestedReason}
                    />
                  )}
                </Grid>
                <Divider />
                <Typography variant="body2" color="secondary" p={1.5}>
                  Job Details
                </Typography>
                <Divider />
                <Box p={1.5}>
                  <Grid container>
                    <Grid item xs={12}>
                      {ReactHtmlParser(quote.detailsNeeds)}
                      <Box my={1.25}>
                        <Divider />
                      </Box>
                    </Grid>

                    <LabelValue label="Budget" value={quote.detailsBudget} />
                    <LabelValue
                      label="Timeframe"
                      value={quote.detailsTimeframe}
                    />
                    <LabelValue
                      label="Location"
                      value={quote.consumerDetailLocation}
                    />
                  </Grid>
                </Box>
              </Paper>
            </Box>

            <Box
              mui
              width={{ sm: "100%", md: servicingWidth }}
              flexDirection="column"
              display={{
                xs: "none",
                sm: "flex",
              }}
              py={1.5}
            >
              {matchesUp && renderContactCard()}
            </Box>
          </Box>

          <Box
            mui
            width="100%"
            flexDirection="column"
            display={{
              xs: "flex",
              sm: "none",
            }}
          >
            {matchesDown && renderContactCard()}
          </Box>

          <br />
          {!isPublicRoute && (
            <BackButton status={quote.status} quotes={quotes} />
          )}
        </>
      )}
    </MainContent>
  );
};

export default withGatewayApi(withDialogState(withRouter(QuoteDetails)));
